<template>
  <v-container
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-center align-center">
          <img src="@/assets/svg/404.svg" style="width: 400px" />
        </div>
        <div class="title font-weight-bold">{{ $t("app.404") }}</div>
        <v-btn to="/" color="primary" text>
          {{ $t("app.404_btn") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FourOhFour",
  metaInfo: {
    title: "404",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  created() {
    this.$ssrContext && this.$ssrContext.res.status(404);
  }
};
</script>
